import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CardBlogPost from "../components/card-blog-post";
const EsBlog = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const [posts, setPosts] = useState(data.allMdx.edges);
  const allTags = [
    "Portals D365",
    "D365",
    "Testing",
    "Custom API",
    "C#",
    "Omnichannel",
    "Redis"
  ];
  const [filteredTags, setFilteredTags] = useState([]);

  function toggleTag(tag) {
    var copyTags = filteredTags || [];
    if (copyTags.includes(tag)) {
      copyTags = copyTags.filter(x => x !== tag);
    } else {
      copyTags.push(tag);
    }
    setFilteredTags(copyTags);
    const originalPosts = data.allMdx.edges;
    let postsToFilter = originalPosts.filter(({ node }) => {
      var includeTag = false;
      var tagsFiltered = node.frontmatter.tag.split(";").map(x => x.trim());
      tagsFiltered.forEach(filtered => {
        if (copyTags.includes(filtered)) {
          includeTag = true;
        }
      });
      return includeTag;
    });
    if (copyTags.length === 0) {
      postsToFilter = data.allMdx.edges;
    }
    setPosts(postsToFilter);
  }
  return (
    <Layout location={data.location} title={siteTitle} lang="es">
      <SEO
        title="Blog"
        description="All the posts written until this date you can read all along"
        keywords={[
          "Dyanmics 365",
          "D365",
          "PowerApps",
          "PowerPlatform",
          "PortalsD365"
        ]}
      />
      <div className="border-4 border-gray-800 border-opacity-75 rounded-md flex flex-row w-full flex-wrap justify-center">
        {allTags.map(tag => {
          return (
            <button
              type="button"
              className={`${
                filteredTags.includes(tag) ? "bg-gray-900" : "bg-gray-700"
              } w-1/3 lg:w-44 m-2 px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              onClick={() => toggleTag(tag)}
              key={tag}
            >
              {tag}
            </button>
          );
        })}
      </div>
      <div className="mt-12 max-w-lg mx-auto grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:max-w-none lg:max-w-none">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          const tags = node.frontmatter.tag || "Dynamics CRM";
          const excerpt = node.frontmatter.description || node.excerpt;
          const href = `/es/blog${node.fields.slug}`;
          const date = node.frontmatter.date;
          const min = node.frontmatter.minutes || null;
          const image = node.frontmatter.image || null;
          const lang = node.frontmatter.lang;
          return (
            <CardBlogPost
              key={href}
              title={title}
              tags={tags}
              excerpt={excerpt}
              href={href}
              date={date}
              min={min}
              image={image}
              lang={lang}
              callback={toggleTag}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default EsBlog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { frontmatter: { parenturl: { eq: "es/blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tag
            minutes
            image
            lang
          }
        }
      }
    }
  }
`;
